<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Egreso Extraordinario - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col md="2">
                  <b-form-group label="Nro :">
                    <b-form-input disabled class="text-center" v-model="expense.number"></b-form-input>
                    <small v-if="errors.number" class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago:">
                    <b-form-select  v-model="expense.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Descripción:">
                    <b-form-input type="text" v-model="expense.description"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Importe:">
                    <b-form-input type="number" class="text-right" step="any" v-model="expense.total"></b-form-input>
                    <small v-if="errors.total" class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control btn-primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalProviders />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalProviders from '@/views/components/ModalProvider'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_extraordinary_expense"],
  components:{
      vSelect,
      ModalProviders,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ExtraordinaryExpense',
      role: 3,
      expense: {
          id_extraordinary_expense:'',
          id_user:'',
          number:'',
          payment_method: '008',
          coin:'PEN',
          description:'',
          total:(0).toFixed(2),
          state:1,
      },

      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
      ],

      payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],
      //errors
      errors: {
        number: false,
        description: false,
        coin: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewExpense();
  },
  methods: {
    EditExpense,
    Validate,
    ViewExpense,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function ViewExpense() {
  let id_extraordinary_expense = je.decrypt(this.id_extraordinary_expense);
  let me = this;
  let url = me.url_base + "extraordinary-expense/view/"+id_extraordinary_expense;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.expense.id_extraordinary_expense = response.data.result.id_extraordinary_expense;
        me.expense.id_user = response.data.result.id_user;
        me.expense.number = response.data.result.number;
        me.expense.payment_method = response.data.result.payment_method;
        me.expense.coin = response.data.result.coin;
        me.expense.description = response.data.result.description;
        me.expense.total = response.data.result.total;
        me.expense.state = response.data.result.state;
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditExpense() {
  let me = this;
  me.expense.id_user = me.user.id_user;
  let url = me.url_base + "extraordinary-expense/edit";
  let data = me.expense;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.number = this.expense.number.length == 0 ? true : false;
  this.errors.description = this.expense.description.length == 0 ? true : false;
  this.errors.coin = this.expense.coin.length == 0 ? true : false;
  this.errors.total = this.expense.total.length == 0 ? true : false;
  
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.description) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.coin) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el egreso ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditExpense();
    }
  });
}
</script>
